/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchSettings } from "../redux/actions/settingActions";
import { fetchUserCredentials } from "../redux/actions/adminActions";
import Loader from "./Loader";

import { postData } from "../util";
import "./AdminStats.css";

const AdminStats = ({  fetchUserCredentials }) => {
  const [stats, setStats] = useState({
    orderSize: 0,
    orders: [],
  });

  useEffect(() => {
    fetchSettings();
    pollServerUpdates();
  }, []);
  const history = useHistory();
  const username = localStorage.getItem("username");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [pizzaSellList, setPizzaSellList] = useState({});
  const pizzaList = [
    "Margherita",
    "Prosciutto",
    "Quattro Formaggi",
    "Funghi",
    "Nduja",
    "Quattro Salumi",
    "Diavola",
    "Tartufo",
    "Quattro Stagioni",
    "SOT",
    "Lasagne",
  ];

  const validateUser = (creds) => {
    const dbHash = creds.password;
    const userHash = localStorage.getItem("hash");
    if (dbHash !== userHash) {
      localStorage.setItem("username", "");
      localStorage.setItem("hash", "");
      history.push("admin/login");
    } else {
      setIsLoggedIn(true);
      fetchSettings();
    }
  };

  const pollServerUpdates = async () => {
    const creds = await fetchUserCredentials(username);
    if (creds && creds[0]) {
      validateUser(creds[0]);
    } else {
      history.push("/admin/login");
    }
  };

  const getPizzaCount = (allOrders) => {
    const pizzaCounter = {
      Margherita: 0,
      Prosciutto: 0,
      "Quattro Formaggi": 0,
      Funghi: 0,
      Nduja: 0,
      "Quattro Salumi": 0,
      Diavola: 0,
      Tartufo: 0,
      "Quattro Stagioni": 0,
      SOT: 0,
      Lasagne: 0,
    };
    for (let i = 0; i < allOrders.length; i++) {
      const pizzas = allOrders[i].pizzas.split(",");
      for (let j = 0; j < pizzas.length; j++) {
        const pizzaName = pizzas[j];
        for (let k = 0; k < pizzaList.length; k++) {
          if (pizzaName.includes(pizzaList[k])) {
            pizzaCounter[pizzaList[k]] += 1;
          }
        }
      }
    }
    setPizzaSellList(pizzaCounter);
  };

  const search = (dateFrom, dateTo) => {
    postData("/api/orders/stats/date", { dateFrom, dateTo }).then((data) => {
      setStats({
        orderSize: data.orders.length,
        orders: data.orders,
      });
      getPizzaCount(data.orders);
    });
  };

  const selectDate = (month) => {
    let dateStart;
    let dateEnd;
    switch (month) {
      case "1":
        dateStart = "2025-01-01";
        dateEnd = "2025-02-01";
        break;
      case "2":
        dateStart = "2025-02-01";
        dateEnd = "2025-03-01";
        break;
      case "3":
        dateStart = "2025-03-01";
        dateEnd = "2025-04-01";
        break;
      case "4":
        dateStart = "2025-04-01";
        dateEnd = "2025-05-01";
        break;
      case "5":
        dateStart = "2025-05-01";
        dateEnd = "2025-06-01";
        break;
      case "6":
        dateStart = "2025-06-01";
        dateEnd = "2025-07-01";
        break;
      case "7":
        dateStart = "2025-07-01";
        dateEnd = "2025-08-01";
        break;
      case "8":
        dateStart = "2025-08-01";
        dateEnd = "2025-09-01";
        break;
      case "9":
        dateStart = "2025-09-01";
        dateEnd = "2025-10-01";
        break;
      case "10":
        dateStart = "2025-10-01";
        dateEnd = "2025-11-01";
        break;
      case "11":
        dateStart = "2025-11-01";
        dateEnd = "2025-12-01";
        break;
      case "12":
        dateStart = "2025-12-01";
        dateEnd = "2026-01-01";
        break;
      case "13":
        dateStart = "2024-01-01";
        dateEnd = "2024-02-01";
        break;
      case "14":
        dateStart = "2024-02-01";
        dateEnd = "2024-03-01";
        break;
      case "15":
        dateStart = "2024-03-01";
        dateEnd = "2024-04-01";
        break;
      case "16":
        dateStart = "2024-04-01";
        dateEnd = "2024-05-01";
        break;
      case "17":
        dateStart = "2024-05-01";
        dateEnd = "2024-06-01";
        break;
      case "18":
        dateStart = "2024-06-01";
        dateEnd = "2024-07-01";
        break;
      case "19":
        dateStart = "2024-07-01";
        dateEnd = "2024-08-01";
        break;
      case "20":
        dateStart = "2024-08-01";
        dateEnd = "2024-09-01";
        break;
      case "21":
        dateStart = "2024-09-01";
        dateEnd = "2024-10-01";
        break;
      case "22":
        dateStart = "2024-10-01";
        dateEnd = "2024-11-01";
        break;
      case "23":
        dateStart = "2024-11-01";
        dateEnd = "2024-12-01";
        break;
      case "24":
        dateStart = "2024-12-01";
        dateEnd = "2025-01-01";
        break;
      default:
        dateStart = "2020-04-01";
        dateEnd = "2020-05-01";
    }
    search(dateStart, dateEnd);
  };

  // ALL
  const totalPizzasAndSodas = _.sumBy(stats.orders, "orderItemsAll");
  const totalPizzas = _.sumBy(stats.orders, "orderItemsPizzas");
  const sodas = totalPizzasAndSodas - totalPizzas;
  const totalPasta1 = _.sumBy(stats.orders, "orderItemsPastas");
  const allSales = _.sumBy(stats.orders, "orderPrice");

  // CAMPAIGNS
  const isCampaigns = stats.orders.filter((o) => o.campaign !== "");
  const pizzasCampaign = _.sumBy(isCampaigns, "orderItemsPizzas");

  // Gjuteriporten SÖDERTÄLJE
  const ordersSodertalje = stats.orders.filter(
    (o) => o.store === "Gjuteriporten Södertälje"
  );
  const totalPizzasAndSodasSodertalje = _.sumBy(
    ordersSodertalje,
    "orderItemsAll"
  );
  const totalPizzasSodertalje = _.sumBy(ordersSodertalje, "orderItemsPizzas");
  const sodasSodertalje = totalPizzasAndSodasSodertalje - totalPizzasSodertalje;
  const totalPasta1Sodertalje = _.sumBy(ordersSodertalje, "orderItemsPastas");
  const salesSodertalje = _.sumBy(ordersSodertalje, "orderPrice");

  // Polhem Södertälje
  const ordersSodertaljePolhem = stats.orders.filter(
    (o) => o.store === "Polhemsgatan Södertälje"
  );
  const totalPizzasAndSodasSodertaljePolhem = _.sumBy(
    ordersSodertaljePolhem,
    "orderItemsAll"
  );
  const totalPizzasSodertaljePolhem = _.sumBy(
    ordersSodertaljePolhem,
    "orderItemsPizzas"
  );
  const sodasSodertaljePolhem =
    totalPizzasAndSodasSodertaljePolhem - totalPizzasSodertaljePolhem;
  const totalPasta1SodertaljePolhem = _.sumBy(
    ordersSodertaljePolhem,
    "orderItemsPastas"
  );
  const salesSodertaljePolhem = _.sumBy(ordersSodertaljePolhem, "orderPrice");

  // Foodtruck 1
  const ordersFoodtruck1 = stats.orders.filter(
    (o) => o.store === "Foodtruck #1"
  );
  const totalPizzasAndSodasFoodtruck1 = _.sumBy(
    ordersFoodtruck1,
    "orderItemsAll"
  );
  const totalPizzasFoodtruck1 = _.sumBy(ordersFoodtruck1, "orderItemsPizzas");
  const sodasFoodtruck1 = totalPizzasAndSodasFoodtruck1 - totalPizzasFoodtruck1;
  const salesFoodtruck1 = _.sumBy(ordersFoodtruck1, "orderPrice");

  // Uppsala
  const ordersUppsala = stats.orders.filter(
    (o) => o.store === "Uppsala Boländerna"
  );
  const totalPizzasAndSodasUppsala = _.sumBy(ordersUppsala, "orderItemsAll");
  const totalPizzasUppsala = _.sumBy(ordersUppsala, "orderItemsPizzas");
  const sodasUppsala = totalPizzasAndSodasUppsala - totalPizzasUppsala;
  const salesUppsala = _.sumBy(ordersUppsala, "orderPrice");

  // Uppsala 2
  const ordersUppsala2 = stats.orders.filter((o) => o.store === "Hölö");
  const totalPizzasAndSodasUppsala2 = _.sumBy(ordersUppsala2, "orderItemsAll");
  const totalPizzasUppsala2 = _.sumBy(ordersUppsala2, "orderItemsPizzas");
  const sodasUppsala2 = totalPizzasAndSodasUppsala2 - totalPizzasUppsala2;
  const salesUppsala2 = _.sumBy(ordersUppsala2, "orderPrice");

  return isLoggedIn ? (
    <div className="adminStats_container">
      <div className="adminStats_headerContainer">
        <button
          type="button"
          className="admin_settingsButton"
          onClick={() => history.push("/admin")}
        >
          <FontAwesomeIcon className="pizzas_cartIcon" icon={faChevronLeft} />
        </button>
        <span className="adminStats_header">SOT - Statistik</span>
        <div />
      </div>
      <div className="admin_ordersTodayOuterContainer">
        <select
          className="adminStats_datePicker"
          onChange={(e) => selectDate(e.target.value)}
        >
          <option value="Välj">Välj månad</option>
          <option value="1">Januari 2025</option>
          <option value="2">Februari 2025</option>
          <option value="3">Mars 2025</option>
          <option value="4">April 2025</option>
          <option value="5">Maj 2025</option>
          <option value="6">Juni 2025</option>
          <option value="7">Juli 2025</option>
          <option value="8">Augusti 2025</option>
          <option value="9">September 2025</option>
          <option value="10">Oktober 2025</option>
          <option value="11">November 2025</option>
          <option value="12">December 2025</option>
          <option value="13">Januari 2024</option>
          <option value="14">Februari 2024</option>
          <option value="15">Mars 2024</option>
          <option value="16">April 2024</option>
          <option value="17">Maj 2024</option>
          <option value="18">Juni 2024</option>
          <option value="19">Juli 2024</option>
          <option value="20">Augusti 2024</option>
          <option value="21">September 2024</option>
          <option value="22">Oktober 2024</option>
          <option value="23">November 2024</option>
          <option value="24">December 2024</option>
        </select>

        <div className="adminStats_outerTable">
          <div className="adminStats_column">
            <h3>Alla ordrar</h3>
            <span className="adminStats_spanText">
              Ordrar: {stats.orderSize} ({isCampaigns.length})
            </span>

            <span className="adminStats_spanText">
              Alla pizzor: {totalPizzas} ({pizzasCampaign})
            </span>

            <span className="adminStats_spanText">Pasta: {totalPasta1}</span>

            <span className="adminStats_spanText">Läsk: {sodas}</span>

            <span className="adminStats_spanText">Försäljning: {allSales}</span>
          </div>

          <div className="adminStats_column">
            <h3>GP Södertälje</h3>
            <span className="adminStats_spanText">
              Ordrar: {ordersSodertalje.length}
            </span>

            <span className="adminStats_spanText">
              Alla pizzor: {totalPizzasSodertalje}
            </span>

            <span className="adminStats_spanText">
              Pasta: {totalPasta1Sodertalje}
            </span>

            <span className="adminStats_spanText">Läsk: {sodasSodertalje}</span>

            <span className="adminStats_spanText">
              Försäljning: {salesSodertalje}
            </span>
          </div>

          <div className="adminStats_column">
            <h3>Polhem Södertälje</h3>
            <span className="adminStats_spanText">
              Ordrar: {ordersSodertaljePolhem.length}
            </span>

            <span className="adminStats_spanText">
              Alla pizzor: {totalPizzasSodertaljePolhem}
            </span>

            <span className="adminStats_spanText">
              Pasta: {totalPasta1SodertaljePolhem}
            </span>

            <span className="adminStats_spanText">
              Läsk: {sodasSodertaljePolhem}
            </span>

            <span className="adminStats_spanText">
              Försäljning: {salesSodertaljePolhem}
            </span>
          </div>

          <div className="adminStats_column">
            <h3>Foodtruck 1</h3>
            <span className="adminStats_spanText">
              Ordrar: {ordersFoodtruck1.length}
            </span>

            <span className="adminStats_spanText">
              Alla pizzor: {totalPizzasFoodtruck1}
            </span>

            <span className="adminStats_spanText">Läsk: {sodasFoodtruck1}</span>

            <span className="adminStats_spanText">
              Försäljning: {salesFoodtruck1}
            </span>
          </div>

          <div className="adminStats_column">
            <h3>Uppsala Boländerna</h3>
            <span className="adminStats_spanText">
              Ordrar: {ordersUppsala.length}
            </span>

            <span className="adminStats_spanText">
              Alla pizzor: {totalPizzasUppsala}
            </span>

            <span className="adminStats_spanText">Läsk: {sodasUppsala}</span>

            <span className="adminStats_spanText">
              Försäljning: {salesUppsala}
            </span>
          </div>

          <div className="adminStats_column">
            <h3>Hölö</h3>
            <span className="adminStats_spanText">
              Ordrar: {ordersUppsala2.length}
            </span>

            <span className="adminStats_spanText">
              Alla pizzor: {totalPizzasUppsala2}
            </span>

            <span className="adminStats_spanText">Läsk: {sodasUppsala2}</span>

            <span className="adminStats_spanText">
              Försäljning: {salesUppsala2}
            </span>
          </div>
        </div>
        <span className="adminStats_footNote">
          *Inom parentes är sålda med kampanjkod
        </span>
      </div>

      <div className="adminStats_outerTable">
        <div className="adminStats_column">
          <h3 className="">Försäljning per pizza</h3>
          <span className="adminStats_spanText">
            {pizzaList[0]}
            {": "}
            {pizzaSellList[pizzaList[0]]}
          </span>

          <span className="adminStats_spanText">
            {pizzaList[1]}
            {": "}
            {pizzaSellList[pizzaList[1]]}
          </span>

          <span className="adminStats_spanText">
            {pizzaList[2]}
            {": "}
            {pizzaSellList[pizzaList[2]]}
          </span>

          <span className="adminStats_spanText">
            {pizzaList[3]}
            {": "}
            {pizzaSellList[pizzaList[3]]}
          </span>

          <span className="adminStats_spanText">
            {pizzaList[4]}
            {": "}
            {pizzaSellList[pizzaList[4]]}
          </span>

          <span className="adminStats_spanText">
            {pizzaList[5]}
            {": "}
            {pizzaSellList[pizzaList[5]]}
          </span>

          <span className="adminStats_spanText">
            {pizzaList[6]}
            {": "}
            {pizzaSellList[pizzaList[6]]}
          </span>

          <span className="adminStats_spanText">
            {pizzaList[7]}
            {": "}
            {pizzaSellList[pizzaList[7]]}
          </span>

          <span className="adminStats_spanText">
            {pizzaList[8]}
            {": "}
            {pizzaSellList[pizzaList[8]]}
          </span>
          <span className="adminStats_spanText">
            {pizzaList[9]}
            {": "}
            {pizzaSellList[pizzaList[9]]}
          </span>

          <span className="adminStats_spanText">
            {pizzaList[10]}
            {": "}
            {pizzaSellList[pizzaList[10]]}
          </span>
        </div>
        <div className="adminStats_column" />
        <div className="adminStats_column" />
      </div>
    </div>
  ) : (
    <div>
      <Loader />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.products.loading,
  settings: state.settings,
  isFetchingSettings: state.settings.loading,
  ordersToday: state.orders.ordersTodayAdmin,
  ordersTodayError: state.orders.ordersTodayErrorAdmin,
  ordersTodayLoading: state.orders.ordersTodayLoadingAdmin,
  credentials: state.admin,
  isFetchingCredentials: state.admin.credentialsLoading,
  credentialsError: state.admin.credentialsError,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSettings: () => dispatch(fetchSettings()),
  fetchUserCredentials: (username) => dispatch(fetchUserCredentials(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminStats);
